<template>
  <div class="attachments">
    <Header back="返回" title="合作项目" index="首页" titleOne="合同管理" titleTwo="合作项目" beforeTitle="编辑" />
    <div class="content">
      <h1>项目信息</h1>
      <el-form
        :rules="projectRule"
        ref="projectRule"
        :model="projectform"
        label-width="80px"
        :label-position="labelPosition"
      >
        <ul>
          <li>
            <span>企业名称</span>
            <p>{{ projectform.company_name }}</p>
          </li>
          <li>
            <span>合同名称</span>
            <p>{{ projectform.contract_name }}</p>
          </li>
          <li>
            <span>项目名称</span>
            <p>{{ projectform.product_name }}</p>
          </li>
          <li>
            <span>项目类型</span>
            <p>{{ projectform.project_type }}</p>
          </li>
          <li>
            <span>项目编号</span>
            <p>{{ projectform.code }}</p>
          </li>
          <li>
            <span>渠道商</span>
            <p>{{ projectform.channel }}</p>
          </li>
          <li>
            <span>所属区域</span>
            <p>{{ projectform.area }}</p>
          </li>
          <li>
            <span>状态</span>
            <p v-if="projectform.status == 0">未开展</p>
            <p v-if="projectform.status == 1">已流转</p>
            <p v-if="projectform.status == 2">已分配</p>
            <p v-if="projectform.status == 3">开展中</p>
            <p v-if="projectform.status == 4">已完结</p>
          </li>
        </ul>
        <div class="clear"></div>
        <el-form-item label="计划提报">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="projectform.submit_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="计划批次">
          <el-input v-model="projectform.batch"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="项目备注" style="width: 100%">
          <el-input
            type="textarea"
            v-model="projectform.explain"
            :autosize="{ minRows: 5, maxRows: 5 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="付款说明" style="width: 100%">
          <el-input
            type="textarea"
            v-model="projectform.payment"
            :autosize="{ minRows: 5, maxRows: 5 }"
          ></el-input>
        </el-form-item>
        <h1>收款模式</h1>
        <el-form-item label="收费方式">
          <el-select v-model="projectform.billing_method" placeholder="请选择">
            <el-option label="固定" :value="1">固定</el-option>
            <el-option label="计提" :value="2">计提</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="总金额（元）">
          <el-input v-model="projectform.total_money"></el-input>
        </el-form-item>
        <el-form-item label="定金（元）">
          <el-input v-model="projectform.deposit"></el-input>
        </el-form-item>
        <el-form-item label="提交（元）">
          <el-input v-model="projectform.submit_money"></el-input>
        </el-form-item>
        <el-form-item label="通过（元）">
          <el-input v-model="projectform.pass_money"></el-input>
        </el-form-item>
        <el-form-item label="资金下达（元）">
          <el-input v-model="projectform.release_money"></el-input>
        </el-form-item>
        <el-form-item label="验收确认（元）">
          <el-input v-model="projectform.check_money"></el-input>
        </el-form-item>
        <h1>项目参与人员（内部）</h1>
        <el-table :data="projectform.participants.inside" style="width: 100%" :key="1">
          <el-table-column width="50">
            <template slot="header">
              <button type="button" @click="addLine" class="addBtn">
                <i class="addLine"></i>
              </button>
            </template>
            <template slot-scope="scope">
              <button
                type="button"
                v-if="projectform.participants.inside.length > 0"
                @click="handleDelete1(scope)"
                class="del-btn"
              >
                <i class="delLine"></i>
              </button>
            </template>
          </el-table-column>
          <!-- <el-table-column v-if="false">
					<template slot-scope="scope">
						<el-input v-model="scope.row.person_type"></el-input>
					</template>
          </el-table-column>-->
          <el-table-column label="姓名">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="职务">
            <template slot-scope="scope">
              <el-input v-model="scope.row.job"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="手机/微信">
            <template slot-scope="scope">
              <el-input v-model="scope.row.mobile"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="邮箱">
            <template slot-scope="scope">
              <el-input v-model="scope.row.email"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="联系地址">
            <template slot-scope="scope">
              <el-input v-model="scope.row.address"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="scope">
              <el-input v-model="scope.row.remark"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <h1>项目参与人员（客户）</h1>
        <el-table :data="projectform.participants.custom" style="width: 100%" :key="2">
          <el-table-column width="50">
            <template slot="header" slot-scope="scope">
              <button type="button" @click="addLine1" class="addBtn">
                <i class="addLine"></i>
              </button>
            </template>
            <template slot-scope="scope">
              <button
                type="button"
                v-if="projectform.participants.custom.length > 0"
                @click="handleDelete2(scope, scope.row)"
                class="del-btn"
              >
                <i class="delLine"></i>
              </button>
            </template>
          </el-table-column>
          <!-- <el-table-column v-if="false">
					<template slot-scope="scope">
						<el-input v-model="scope.row.person_type"></el-input>
					</template>
          </el-table-column>-->
          <el-table-column label="姓名">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="职务">
            <template slot-scope="scope">
              <el-input v-model="scope.row.job"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="手机/微信">
            <template slot-scope="scope">
              <el-input v-model="scope.row.mobile"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="邮箱">
            <template slot-scope="scope">
              <el-input v-model="scope.row.email"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="联系地址">
            <template slot-scope="scope">
              <el-input v-model="scope.row.address"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="scope">
              <el-input v-model="scope.row.remark"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <h1>项目参与人员（政府）</h1>
        <el-table :data="projectform.participants.government" style="width: 100%" :key="3">
          <el-table-column width="50">
            <template slot="header" slot-scope="scope">
              <button type="button" @click="addLine2" class="addBtn">
                <i class="addLine"></i>
              </button>
            </template>
            <template slot-scope="scope">
              <button
                type="button"
                v-if="projectform.participants.government.length > 0"
                @click="handleDelete3(scope, scope.row)"
                class="del-btn"
              >
                <i class="delLine"></i>
              </button>
            </template>
          </el-table-column>
          <!-- <el-table-column v-if="false">
					<template slot-scope="scope">
						<el-input v-model="scope.row.person_type"></el-input>
					</template>
          </el-table-column>-->
          <el-table-column label="姓名">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="职务">
            <template slot-scope="scope">
              <el-input v-model="scope.row.job"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="手机/微信">
            <template slot-scope="scope">
              <el-input v-model="scope.row.mobile"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="邮箱">
            <template slot-scope="scope">
              <el-input v-model="scope.row.email"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="联系地址">
            <template slot-scope="scope">
              <el-input v-model="scope.row.address"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="scope">
              <el-input v-model="scope.row.remark"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <h1>项目参与人员（第三方）</h1>
        <el-table :data="projectform.participants.third_party" style="width: 100%" :key="4">
          <el-table-column width="50">
            <template slot="header" slot-scope="scope">
              <button type="button" @click="addLine3" class="addBtn">
                <i class="addLine"></i>
              </button>
            </template>
            <template slot-scope="scope">
              <button
                type="button"
                v-if="projectform.participants.third_party.length > 0"
                @click="handleDelete4(scope, scope.row)"
                class="del-btn"
              >
                <i class="delLine"></i>
              </button>
            </template>
          </el-table-column>
          <!-- <el-table-column v-if="false">
					<template slot-scope="scope">
						<el-input v-model="scope.row.person_type"></el-input>
					</template>
          </el-table-column>-->
          <el-table-column label="姓名">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="职务">
            <template slot-scope="scope">
              <el-input v-model="scope.row.job"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="手机/微信">
            <template slot-scope="scope">
              <el-input v-model="scope.row.mobile"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="邮箱">
            <template slot-scope="scope">
              <el-input v-model="scope.row.email"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="联系地址">
            <template slot-scope="scope">
              <el-input v-model="scope.row.address"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="scope">
              <el-input v-model="scope.row.remark"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按纽动画
      labelPosition: "top",
      projectform: {
        contract_project_id: '',//合作项目id（添加附加）
        submit_time: "",
        batch: "",
        explain: "",
        payment: "",
        billing_method: "",
        total_money: "",
        deposit: "",
        submit_money: "",
        pass_money: "",
        release_money: "",
        check_money: "",
        participants: {
          inside: [],
          custom: [],
          government: [],
          third_party: [],
        },
      },
      projectRule: {},
    };
  },
  created () {

  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/contract/view_projcet", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.projectform = res.data;
          setTimeout(() => {
            if (this.$route.query.status == 'additional') {
              console.log('附加项目++')
              this.projectform.submit_time = ''
              this.projectform.batch = ''
              this.projectform.explain = ''
              this.projectform.payment = ''
              this.projectform.billing_method = ''
              this.projectform.total_money = ''
              this.projectform.deposit = ''
              this.projectform.submit_money = ''
              this.projectform.pass_money = ''
              this.projectform.release_money = ''
              this.projectform.check_money = ''
              this.projectform.participants.inside = []
              this.projectform.participants.custom = []
              this.projectform.participants.government = []
              this.projectform.participants.third_party = []
            }
          }, 100);
        });
    }
  },
  methods: {
    onSubmit () {
      this.projectform.participants.inside.forEach((item, index) => {
        item.person_type = 1;
      });
      this.projectform.participants.custom.forEach((item, index) => {
        item.person_type = 2;
      });
      this.projectform.participants.government.forEach((item, index) => {
        item.person_type = 3;
      });
      this.projectform.participants.third_party.forEach((item, index) => {
        item.person_type = 4;
      });
      if (this.$route.query.status == 'additional') {
        this.btnLoading = true
        this.projectform.p_id = +this.$route.query.id
        this.projectform.contract_project_id = +this.$route.query.id
        // console.log('附加项目', this.projectform)
        this.$refs.projectRule.validate((vaild) => {
          if (vaild) {
            this.axios
              .post("/api/contract/store_additional_project", this.projectform)
              .then((res) => {
                console.log(res);
                this.$router.go(-1);
                this.$message({
                  type: "success",
                  message: "保存成功!",
                });
                this.btnLoading = false
              })
              .catch((res) => {
                console.log("err:", res);
                this.btnLoading = false
              });
          } else {
            this.$message.error("");
            this.btnLoading = false
          }
        });
      } else {
        console.log('编辑项目')
        this.$refs.projectRule.validate((vaild) => {
          if (vaild) {
            this.btnLoading = true
            this.axios
              .post("/api/contract/store_project", this.projectform)
              .then((res) => {
                console.log(res);
                this.$router.go(-1);
                this.$message({
                  type: "success",
                  message: "保存成功!",
                });
                this.btnLoading = false
              })
              .catch((res) => {
                console.log("err:", res);
                this.btnLoading = false
              });
          } else {
            this.$message.error("");
            this.btnLoading = false
          }
        });
      }
    },
    addLine () {
      //添加行数
      var newValue = {};
      this.projectform.participants.inside.push(newValue);
    },
    handleDelete1 (scope) {
      //删除行数
      console.log(scope, '删除编辑行数index')
      this.projectform.participants.inside.splice(scope.$index, 1);
    },
    save () {
      //这部分应该是保存提交你添加的内容
      console.log(JSON.stringify(this.projectform.participants.inside));
    },
    addLine1 () {
      //添加行数
      var newValue = {};
      this.projectform.participants.custom.push(newValue);
    },
    handleDelete2 (scope) {
      //删除行数
      this.projectform.participants.custom.splice(scope.$index, 1);
    },
    addLine2 () {
      //添加行数
      var newValue = {};
      this.projectform.participants.government.push(newValue);
    },
    handleDelete3 (scope) {
      //删除行数
      this.projectform.participants.government.splice(scope.$index, 1);
    },
    addLine3 () {
      //添加行数
      var newValue = {};
      this.projectform.participants.third_party.push(newValue);
    },
    handleDelete4 (scope) {
      //删除行数
      this.projectform.participants.third_party.splice(scope.$index, 1);
    },
  },
};
</script>

<style scoped="scoped">
.attachments ul li {
  margin-top: 20px;
}

.attachments ul li span {
  color: #404040;
  margin-right: 20px;
  float: left;
}

.attachments ul li p {
  display: inline-block;
  width: 80%;
  margin: 0;
  color: #909399;
}

::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}
.el-form {
  margin-top: 20px;
}
.el-select {
  width: 100%;
}

.upload-demo {
  display: inline-block;
}

.ChooseFile {
  width: 224px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
}

.UploadFile {
  width: 88px;
  height: 40px;
  background: #0f38ff !important;
  color: #fff !important;
  border: none;
  margin-left: 20px;
}

.el-upload-list {
  display: inline-block;
}

.el-upload-list__item-name {
  margin-top: 20px;
}

::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.ImportTop span {
  color: #a6abc7;
}
</style>
